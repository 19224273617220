import { Component, OnInit } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeEn);

import { Portfolio } from '../models/portfolio';
import { Person } from '../models/person';
import { Company } from '../models/company';
import { PortfolioService } from '../portfolio.service';
import { CommonObjectsService } from '../common-objects.service';
import { GlobalObjectService } from '../globalobject.service';
import { GlobalObject } from '../models/global.object';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-portfolios',
  templateUrl: './portfolios.component.html',
  styleUrls: ['./portfolios.component.css']
})
export class PortfoliosComponent implements OnInit {

  portfolios: Portfolio[];
  currencies: GlobalObject[];
  quickCurrencies: GlobalObject[];
  companies: Company[];
  persons: Person[];

  createdPortfolio: Portfolio;

  currentCreationPage: number;

  constructor(private portfolioService: PortfolioService, private commonObjectsService: CommonObjectsService,
              private globalObjectService: GlobalObjectService, private navigationService: NavigationService) {
  }

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        // Loading main data
        this.getPortfolios();
        // Loading additional data
        this.getCommonObjects();
        this.createdPortfolio = new Portfolio();
        this.createdPortfolio['inception_date_as_dt'] = new Date();
        this.currentCreationPage = 1;
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
  }

  getCommonObjects(): void {
    this.globalObjectService.getCurrencies().subscribe(currencies => this.currencies = currencies);
    this.globalObjectService.getQuickCurrencies().subscribe(quickCurrencies => this.quickCurrencies = quickCurrencies);
    this.commonObjectsService.getPersons().subscribe(persons => this.persons = persons);
    this.commonObjectsService.getCompanies().subscribe(companies => this.companies = companies);
  }

  getPortfolios(): void {
    this.portfolioService.getPortfolios().subscribe(portfolios => this.portfolios = portfolios);
  }

  proceedToNextStep() : void {
    this.currentCreationPage = this.currentCreationPage + 1;
    console.log(this.createdPortfolio);
  }

  revertToPrevious() : void {
    this.currentCreationPage = this.currentCreationPage - 1;
    console.log(this.createdPortfolio);
  }

  createPortfolio() : void {
    console.log(this.createdPortfolio);
    this.createdPortfolio.inception_date = this.createdPortfolio['inception_date_as_dt'].toISOString().substring(0,10);
    this.portfolioService.createPortfolio(this.createdPortfolio).subscribe(portfolio => this.getPortfolios());
  }

}
