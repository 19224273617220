import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Person } from './models/person';
import { Company } from './models/company';
import { JWTHttpClient } from './utility/jwt-http-client';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CommonObjectsService {

  private personsURL = environment.apiEndPoint  + '/persons';
  private companiesURL = environment.apiEndPoint  + '/companies';
  private userURL = environment.apiEndPoint  + '/whoami/';
  

  constructor(private http: JWTHttpClient) { }

  getPersons(): Observable<Person[]> {
    return this.http.get<Person[]>(`${this.personsURL}/`)
      .pipe(  tap(persons => this.log('fetched persons')),
              catchError(this.handleError('getPersons', []))
      );
  }

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.companiesURL}/`)
      .pipe(  tap(companies => this.log('fetched companies')),
              catchError(this.handleError('getCompanies', []))
      );
  }

  getUser(): Observable<any> {
    return this.http.get<any>(`${this.userURL}`).pipe(
      tap(companies => this.log('fetched user')),
              catchError(this.handleError('getUser', undefined))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('CommonObjectsService: ' + message);
  }
}
