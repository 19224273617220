import { GlobalObject } from './global.object';
import { Account } from './account';
import { Person } from './person';
import { Company } from './company';

export class Portfolio {
  id: number;
  identifier : string;
  name : string;
  currency : GlobalObject;
  active : boolean;
  inception_date : string;
  closing_date : string;
  management_company : Company;
  relationship_manager : Person;
  bank : Company;
  accounts: Account[];
  provider : Company;
  last_update : string;
  last_computation : string;
  logo : string;
  current_aum_local : number;
  current_aum_mgmt : number;

  previous_day : number;
  week_to_date : number;
  month_to_date : number;
  quarter_to_date : number;
  year_to_date : number;

  additional_information : object;
  additional_description : object;

}