import { Component } from '@angular/core';
import { NavigationService } from './navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title : string = 'FinanCE';
  userLoginStatus : boolean = false;
  
  constructor(private navigationService: NavigationService) {
    this.navigationService.userLoginStatus.subscribe(userLoginStatus => this.userLoginStatus = userLoginStatus);
  };
}