import { Component, OnInit } from '@angular/core';

import { Security } from '../models/security'
import { SecuritiesService } from '../securities.service';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-securities',
  templateUrl: './securities.component.html',
  styleUrls: ['./securities.component.css']
})
export class SecuritiesComponent implements OnInit {

  searchedText: string;
  securities: Security[];
  searchLaunched : boolean = false;

  constructor(private securitiesService: SecuritiesService, private navigationService: NavigationService) { }

  ngOnInit() {
    this.securities = [];
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        // NOTHING TO DO YET
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
  }

  onSearch(): void {
    this.searchLaunched = true;
    this.securitiesService.getFilteredSecurities(this.searchedText).subscribe(securities => this.securities = securities);
  }

}
