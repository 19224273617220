import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.css']
})
export class OperationDetailsComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        // NOTHING TO DO YET
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
  }

}
