import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Company } from './models/company'

import base64url from 'base64url';
import { JWTHttpClient } from './utility/jwt-http-client';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class CompanyService {

  private companiesURL = environment.apiEndPoint  + '/companies';

  constructor(private http: JWTHttpClient) { }

  getCompany(id: number): Observable<Company> {
    const url = `${this.companiesURL}/${id}`;
    return this.http.get<Company>(url).pipe(
      tap(_ => this.log(`fetched company id=${id}`)),
      catchError(this.handleError<Company>(`getCompany id=${id}`))
    );
  }

  getCompanies() : Observable<Company[]> {
    return this.http.get<Company[]>(this.companiesURL)
      .pipe(  tap(companies => this.log('fetched companies')),
              catchError(this.handleError('getCompanies', []))
      );
  }

  createCompany(company : Company): Observable<Company> {
    const url = `${this.companiesURL}/`;
    return this.http.post<Company>(url, company).pipe(
      tap(_ => this.log(`create company`)),
      catchError(this.handleError<Company>(`createCompany id=${company.default_name}`))
    );
  }

  deleteCompany(company: Company) : Observable<any> {
    const url = `${this.companiesURL}/${company.id}/`;
    return this.http.delete<Company>(url).pipe(
      tap(_ => this.log(`deletre company`)),
      catchError(this.handleError<Company>(`delete id=${company.id}`))
    );
  }

  getFilteredCompanies(_filter: string) : Observable<Company[]> {
    const url = `${this.companiesURL}_search/` + base64url(_filter) + '/';
    return this.http.get<Company[]>(url)
      .pipe(  tap(companies => this.log('fetched filtered companies')),
              catchError(this.handleError('getFilteredCompanies', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('CompaniesService: ' + message);
  }

}
