import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortfoliosComponent } from './portfolios/portfolios.component';
import { PortfolioDetailsComponent }  from './portfolio-details/portfolio-details.component';
import { ExternalSecuritiesComponent } from './external-securities/external-securities.component';
import { ExternalSecurityDetailsComponent } from './external-security-details/external-security-details.component';
import { SecuritiesComponent } from './securities/securities.component';
import { SecurityDetailsComponent } from './security-details/security-details.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { CompaniesComponent } from './companies/companies.component';
import { PersonsComponent } from './persons/persons.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'my-portfolios', component: PortfoliosComponent},
  {path: 'portfolio-details/:id', component: PortfolioDetailsComponent},
  {path: 'external-securities-mgmt', component: ExternalSecuritiesComponent},
  {path: 'external-security-details/:id', component: ExternalSecurityDetailsComponent},
  {path: 'securities-mgmt', component: SecuritiesComponent},
  {path: 'security-details/:id', component: SecurityDetailsComponent},
  {path: 'account-details/:portfolio_id/:account_id', component: AccountDetailsComponent},
  {path: 'companies-mgmt', component: CompaniesComponent },
  {path: 'persons-mgmt', component: PersonsComponent },
  {path: 'login', component: LoginComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
