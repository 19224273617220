import { GlobalObject } from './global.object';
import { Account } from './account';
import { Security } from './security';

export class Operation {
  id: number;
  identifier : string;
  name : string;
  description : string;
  spot_rate : number;
  amount : number;
  amount_portfolio : number;
  amount_management : number;
  operation_date : string;
  value_date : string;
  status : GlobalObject;
  
  additional_information : object;
  additional_description : object;
  
  operation_type : GlobalObject;
  source : Account;
  target : Account;
  security : Security;
  quantity : number;
  price : number;
  
  associated_operation : Operation;
}