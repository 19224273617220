import { Component, OnInit } from '@angular/core';

import { Person } from '../models/person'
import { PersonService } from '../person.service';
import { CommonObjectsService } from '../common-objects.service';
import { GlobalObjectService } from '../globalobject.service';
import { GlobalObject } from '../models/global.object';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.css']
})
export class PersonsComponent implements OnInit {

  searchedText: string;
  persons: Person[];
  searchLaunched : boolean = false;

  createdPerson: Person;

  currentCreationPage: number;

  quickCurrencies: GlobalObject[];

  constructor(private personService: PersonService, private commonObjectsService: CommonObjectsService, private globalObjectService: GlobalObjectService, private navigationService: NavigationService) { }

  ngOnInit() {
    this.persons = [];
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.getCommonObjects();
        this.createdPerson = new Person();
        this.currentCreationPage = 1;
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
  }

  getCommonObjects(): void {
    this.globalObjectService.getQuickCurrencies().subscribe(quickCurrencies => this.quickCurrencies = quickCurrencies);
    this.commonObjectsService.getPersons().subscribe(persons => this.persons = persons);
    this.onSearch();
  }

  proceedToNextStep() : void {
    this.currentCreationPage = this.currentCreationPage + 1;
    console.log(this.createdPerson);
  }

  revertToPrevious() : void {
    this.currentCreationPage = this.currentCreationPage - 1;
    console.log(this.createdPerson);
  }

  onSearch(): void {
    this.searchLaunched = true;
    if (this.searchedText===undefined || this.searchedText==='') {
      this.commonObjectsService.getPersons().subscribe(persons => this.persons = persons);
    } else {
      this.personService.getFilteredPersons(this.searchedText).subscribe(persons => this.persons = persons);
    }
  }

  createPerson() : void {
    console.log(this.createdPerson);
    this.createdPerson.default_name = undefined;
    this.personService.createPerson(this.createdPerson).subscribe(person => this.onSearch());
  }

  deletePerson(person) : void {
    console.log(person);
    this.personService.deletePerson(person).subscribe(person => this.onSearch());
  }
}
