import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ExternalSecurity } from './models/external.security';
import { TrackToken } from './models/track.token';
import { JWTHttpClient } from './utility/jwt-http-client';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ExternalSecuritiesService {

  private externalSecuritiesURL = environment.apiEndPoint  + '/external_securities';

  constructor(private http: JWTHttpClient) { }

  getExternalSecurityHistory(id: number) : Observable<TrackToken[]> {
    const url = `${this.externalSecuritiesURL}_history/${id}/`;
    return this.http.get<TrackToken[]>(url)
      .pipe(  tap(externalSecurityHistory => this.log('fetched external security history')),
              catchError(this.handleError('getExternalSecuritiesHistory', []))
      );
  }

  getExternalSecurity(id: number): Observable<ExternalSecurity> {
    const url = `${this.externalSecuritiesURL}/${id}`;
    return this.http.get<ExternalSecurity>(url).pipe(
      tap(_ => this.log(`fetched external security id=${id}`)),
      catchError(this.handleError<ExternalSecurity>(`getExternalSecurity id=${id}`))
    );
  }


  updateExternalSecurity(security: ExternalSecurity): Observable<ExternalSecurity> {
    const url = `${this.externalSecuritiesURL}/${security.id}/`;
    return this.http.patch<ExternalSecurity>(url, security).pipe(
      tap(_ => this.log(`updated external security id=${security.id}`)),
      catchError(this.handleError<ExternalSecurity>(`updateExternalSecurity id=${security.id}`))
    );
  }

  getExternalSecurities() : Observable<ExternalSecurity[]> {
    return this.http.get<ExternalSecurity[]>(this.externalSecuritiesURL)
      .pipe(  tap(externalSecurities => this.log('fetched external security')),
              catchError(this.handleError('getExternalSecurities', []))
      );
  }

  getUnmappedExternalSecurities() : Observable<ExternalSecurity[]> {
    return this.http.get<ExternalSecurity[]>(this.externalSecuritiesURL + '_unmapped')
      .pipe(  tap(externalSecurities => this.log('fetched unmapped external security')),
              catchError(this.handleError('getUnmappedExternalSecurities', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('ExternalSecuritiesService: ' + message);
  }


}
