import { Component, OnInit, Input } from '@angular/core';
import { Portfolio } from '../models/portfolio';
import { Account } from '../models/account';
import { Operation } from '../models/operation';
import { MoneyAccountChain } from '../models/money-account-chain';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { PortfolioService }  from '../portfolio.service';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private portfolioService: PortfolioService, private navigationService: NavigationService, private location: Location) { }

  @Input() portfolio: Portfolio;
  @Input() account: Account;
  @Input() operations: MoneyAccountChain[];

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.getAccountInformation();
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
    
  }

  getAccountInformation(): void {
    const portfolio_id = +this.route.snapshot.paramMap.get('portfolio_id');
    const account_id = +this.route.snapshot.paramMap.get('account_id');
    this.portfolioService.getPortfolio(portfolio_id).subscribe(portfolio => this.portfolio = portfolio);
    this.portfolioService.getPortfolioAccount(account_id).subscribe(account => this.account = account);
    this.portfolioService.getPortfolioAccountOperations(account_id).subscribe(operations => this.operations = operations);
  }

}
