import { Component, OnInit } from '@angular/core';

import { Company } from '../models/company'
import { CompanyService } from '../company.service';
import { CommonObjectsService } from '../common-objects.service';
import { GlobalObjectService } from '../globalobject.service';
import { GlobalObject } from '../models/global.object';
import { Person } from '../models/person';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  searchedText: string;
  companies: Company[];
  searchLaunched : boolean = false;

  createdCompany: Company;

  currentCreationPage: number;

  quickCurrencies: GlobalObject[];
  persons: Person[];

  constructor(private companyService: CompanyService, private commonObjectsService: CommonObjectsService, private globalObjectService: GlobalObjectService, private navigationService: NavigationService) { }

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.companies = [];
        this.getCommonObjects();
        this.createdCompany = new Company();
        this.createdCompany['creation_date_as_dt'] = new Date();
        this.currentCreationPage = 1;
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();

  }

  getCommonObjects(): void {
    this.globalObjectService.getQuickCurrencies().subscribe(quickCurrencies => this.quickCurrencies = quickCurrencies);
    this.commonObjectsService.getPersons().subscribe(persons => this.persons = persons);
    this.onSearch();
  }

  proceedToNextStep() : void {
    this.currentCreationPage = this.currentCreationPage + 1;
    console.log(this.createdCompany);
  }

  revertToPrevious() : void {
    this.currentCreationPage = this.currentCreationPage - 1;
    console.log(this.createdCompany);
  }

  onSearch(): void {
    this.searchLaunched = true;
    if (this.searchedText===undefined || this.searchedText==='') {
      this.commonObjectsService.getCompanies().subscribe(companies => this.companies = companies);
    } else {
      this.companyService.getFilteredCompanies(this.searchedText).subscribe(companies => this.companies = companies);
    }
  }

  createCompany() : void {
    console.log(this.createdCompany);
    this.createdCompany.creation_date = this.createdCompany['creation_date_as_dt'].toISOString().substring(0,10);
    this.companyService.createCompany(this.createdCompany).subscribe(company => this.onSearch());
  }

  deleteCompany(company) : void {
    console.log(company);
    this.companyService.deleteCompany(company).subscribe(company => this.onSearch());
  }
}
