import { GlobalObject } from './global.object';
import { Company } from './company';

export class Security {
    id: number;
    identifier : string;
    name : string;
    currency : GlobalObject;
    active : boolean;
    inception_date : string;
    closing_date : string;
    management_company : Company;
    bank : Company;
    provider : Company;
    provider_identifier : string;
    last_update : string;
    type : GlobalObject;
    logo : string;
    additional_information : object;
    additional_description : object;
}