import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import localeEn from '@angular/common/locales/en';
import { environment } from '../../environments/environment';
import { CommonObjectsService } from '../common-objects.service';
import { NavigationService } from '../navigation.service';

registerLocaleData(localeEn);

const loginURL : string = environment.apiEndPoint + '/login/';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private user: User;
  private loggedIn : boolean = true;
  
  constructor(private http: HttpClient, private router: Router, private commonObjectsService: CommonObjectsService, private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.user = new User();
    this.commonObjectsService.getUser().subscribe(user => {
      this.navigationService.setCurrentUser(user);
      if (user===undefined || Object.keys(user).length==0) {
        this.loggedIn = false;
      } else {
        this.router.navigate(['/my-portfolios'])
      }
    });
  }

  onSubmit() {
    if (this.user.username != null && this.user.password != null) {
      this.http.post(loginURL, {
        'username': this.user.username,
        'password': this.user.password
      }).subscribe(
        response => {
          if(response != null) {
            localStorage.setItem("finance_jwt_token", response['access']);
            localStorage.setItem("finance_session_id", response['session_id']);
            localStorage.setItem("finance_jwt_token_refresh", response['refresh']);
            this.sucessfulFeedback();
            setTimeout(() => this.router.navigate(['/my-portfolios']), 250)
          } else {
            this.errorFeedback();
            document.getElementById("password").focus();
            setTimeout(() => {
              this.clearElement(document.getElementById("login_feedback"));
            }, 2000)
          }
        });
    }
  }

  logout() {
    if (localStorage.getItem('finance_jwt_token') != null) {
      localStorage.removeItem('finance_jwt_token');
      localStorage.removeItem('finance_session_id');
      localStorage.removeItem('finance_jwt_token_refresh');
      this.logoutFeedback();
      setTimeout(() => this.clearElement(document.getElementById("login_feedback")), 2000)
    }
  }

  clearElement(e:HTMLElement) {
    for (var i=0; i <= e.classList.length; i++) {
      e.classList.remove(e.classList.item(0));
    }
    e.innerHTML = ""
  }

  sucessfulFeedback() {
    var e = document.getElementById("login_feedback");
    this.clearElement(e)

    e.innerHTML = "<h5>Successful login</h5>";
    e.classList.add("alert");
    e.classList.add("alert-success");
  }

  errorFeedback() {
    var e = document.getElementById("login_feedback");
    this.clearElement(e)

    e.innerHTML = "<h5>Invalid credentials</h5>";
    e.classList.add("alert");
    e.classList.add("alert-danger");
  }

  logoutFeedback() {
    var e = document.getElementById("login_feedback");
    this.clearElement(e)

    e.innerHTML = "<h5>Successful logout</h5>";
    e.classList.add("alert");
    e.classList.add("alert-info");
  }
}

export class User {
  username: string;
  password: string;
}