import { Injectable, Output, EventEmitter } from '@angular/core';
import { CommonObjectsService } from './common-objects.service';
import { JWTHttpClient } from './utility/jwt-http-client';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  currentUser : any; // TODO: Implement model

  @Output() userLoginStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(private commonService: CommonObjectsService, private jwtClient: JWTHttpClient, private router: Router) {
    this.jwtClient.refreshEvent.subscribe(response => this.commonService.getUser().subscribe(user=> this.setCurrentUser(user)));
  }

  checkLoggedIn() : void {
    this.jwtClient.refreshToken();
  }

  setCurrentUser(user : any) : void {
    this.currentUser = user;
    this.userLoginStatus.emit(user!==undefined && Object.keys(user).length>0);
  }

  routeToLogin() : void {
    this.router.navigate(['/login']);
  }
}
