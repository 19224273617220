import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Person } from './models/person'

import base64url from 'base64url';
import { JWTHttpClient } from './utility/jwt-http-client';

@Injectable()
export class PersonService {

  private personsURL = environment.apiEndPoint  + '/persons';

  constructor(private http: JWTHttpClient) { }

  getPerson(id: number): Observable<Person> {
    const url = `${this.personsURL}/${id}`;
    return this.http.get<Person>(url).pipe(
      tap(_ => this.log(`fetched person id=${id}`)),
      catchError(this.handleError<Person>(`getPerson id=${id}`))
    );
  }

  getPersons() : Observable<Person[]> {
    return this.http.get<Person[]>(this.personsURL)
      .pipe(  tap(persons => this.log('fetched persons')),
              catchError(this.handleError('getPersons', []))
      );
  }

  createPerson(person : Person): Observable<Person> {
    const url = `${this.personsURL}/`;
    if (person.default_name===undefined || person.default_name==='') {
      person.default_name = person.last_name.toLocaleUpperCase() + ' ' + person.first_name;
    }
    return this.http.post<Person>(url, person).pipe(
      tap(_ => this.log(`create person`)),
      catchError(this.handleError<Person>(`createPerson id=${person.default_name}`))
    );
  }

  deletePerson(person: Person) : Observable<any> {
    const url = `${this.personsURL}/${person.id}/`;
    return this.http.delete<Person>(url).pipe(
      tap(_ => this.log(`deletre person`)),
      catchError(this.handleError<Person>(`delete id=${person.id}`))
    );
  }

  getFilteredPersons(_filter: string) : Observable<Person[]> {
    const url = `${this.personsURL}_search/` + base64url(_filter) + '/';
    return this.http.get<Person[]>(url)
      .pipe(  tap(persons => this.log('fetched filtered persons')),
              catchError(this.handleError('getFilteredPersons', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('PersonsService: ' + message);
  }

}
