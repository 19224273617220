import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Portfolio } from './models/portfolio';
import { Account } from './models/account';
import { MoneyAccountChain } from './models/money-account-chain';
import { TrackToken } from './models/track.token';
import { PortfolioSetup } from './models/portfolios-setup';
import { SecurityHolding } from './models/security-holding';
import { Operation } from './models/operation';
import { JWTHttpClient } from './utility/jwt-http-client';


@Injectable()
export class PortfolioService {

  private portfoliosURL = environment.apiEndPoint  + '/portfolios';
  private accountsURL = environment.apiEndPoint  + '/accounts';
  private operationsURL = environment.apiEndPoint  + '/account_operations';
  private holdingsURL = environment.apiEndPoint  + '/portfolio/holdings';
  private securityOperationsURL = environment.apiEndPoint  + '/portfolio/security/operations';
  private valuationComputeURL = environment.apiEndPoint  + '/portfolio/compute';


  constructor(private http: JWTHttpClient) { }

  getSetup(): Observable<PortfolioSetup> {
    const url = `${this.portfoliosURL}_setup/`;
    return this.http.get<PortfolioSetup>(url).pipe(
      tap(_ => this.log(`fetched portfolios setup`)),
      catchError(this.handleError<PortfolioSetup>(`getSetup`))
    );
  }

  computePortfolio(id: number): Observable<any> {
    const url = `${this.valuationComputeURL}/${id}/`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`computed portfolio id=${id}`)),
      catchError(this.handleError<any>(`computePortfolio id=${id}`))
    );
  }

  getPortfolio(id: number): Observable<Portfolio> {
    const url = `${this.portfoliosURL}/${id}/`;
    return this.http.get<Portfolio>(url).pipe(
      tap(_ => this.log(`fetched portfolio id=${id}`)),
      catchError(this.handleError<Portfolio>(`getPortfolio id=${id}`))
    );
  }

  getPortfolioHistory(id: number, data_type: string): Observable<TrackToken[]> {
    const url = `${this.portfoliosURL}_history/${id}/${data_type}/`;
    return this.http.get<TrackToken[]>(url)
      .pipe(  tap(portfolioHistory => this.log('fetched portfolio history')),
              catchError(this.handleError('getPortfolioHistory', []))
      );
  }

  getPortfolioAccount(id: number): Observable<Account> {
    const url = `${this.accountsURL}/${id}/`;
    return this.http.get<Account>(url).pipe(
      tap(_ => this.log(`fetched account id=${id}`)),
      catchError(this.handleError<Account>(`getAccount id=${id}`))
    );
  }

  getPortfolioAccountOperations(id: number): Observable<MoneyAccountChain[]> {
    const url = `${this.operationsURL}/${id}/`;
    return this.http.get<MoneyAccountChain[]>(url)
      .pipe(  tap(operations => this.log('fetched operations')),
              catchError(this.handleError('getPortfolioAccountOperations', []))
      );
  }

  getPortfolioHoldings(id: number): Observable<SecurityHolding[]> {
    const url = `${this.holdingsURL}/${id}/`;
    return this.http.get<SecurityHolding[]>(url)
      .pipe(  tap(operations => this.log('fetched holdings')),
              catchError(this.handleError('getPortfolioHoldings', []))
      );
  }

  getPortfolioSecurityOperations(portfolio: Portfolio, accountId: number, securityId: number): Observable<Operation[]> {
    const url = `${this.securityOperationsURL}/${portfolio.id}/${accountId}/${securityId}/`;
    return this.http.get<Operation[]>(url)
      .pipe(  tap(operations => this.log('fetched security operations')),
              catchError(this.handleError('getPortfolioSecurityOperations', []))
      );
  }
  

  getPortfolios(): Observable<Portfolio[]> {
    return this.http.get<Portfolio[]>(`${this.portfoliosURL}/`)
      .pipe(  tap(portfolios => this.log('fetched portfolios')),
              catchError(this.handleError('getPortfolios', []))
      );
  }

  updatePortfolio(portfolio): Observable<Portfolio> {
    const url = `${this.portfoliosURL}/${portfolio.id}/`;
    return this.http.patch<Portfolio>(url, portfolio).pipe(
      tap(_ => this.log(`update portfolio`)),
      catchError(this.handleError<Portfolio>(`updatePortfolio id=${portfolio.name}`))
    );
  }

  createPortfolio(portfolio): Observable<Portfolio> {
    const url = `${this.portfoliosURL}/`;
    return this.http.post<Portfolio>(url, portfolio).pipe(
      tap(_ => this.log(`create portfolio`)),
      catchError(this.handleError<Portfolio>(`createPortfolio id=${portfolio.name}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('PortfolioService: ' + message);
  }

}
