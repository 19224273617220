import { GlobalObject } from './global.object';
import { Address } from './address';
import { Email } from './email';
import { Phone } from './phone';
import { CompanyMember } from './company-member';
import { CompanySubsidiary } from './company-subsidiary';

export class Company {
    id: number;
    default_name : string;
    creation_date : string;
    base_currency : GlobalObject;
    addresses : Address[];
    emails : Email[];
    phones : Phone[];
    members : CompanyMember[];
    subsidiaries : CompanySubsidiary[];
    active : boolean;
    logo : string;
    is_provider : boolean;
    provider_code : string;
}