import { Component, OnInit, Input } from '@angular/core';
import { ExternalSecuritiesService } from '../external-securities.service'
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ExternalSecurity } from '../models/external.security'
import { TrackToken } from '../models/track.token'
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-external-security-details',
  templateUrl: './external-security-details.component.html',
  styleUrls: ['./external-security-details.component.css']
})
export class ExternalSecurityDetailsComponent implements OnInit {

  @Input() externalSecurity: ExternalSecurity;
  options: Object;

  constructor(private route: ActivatedRoute, private externalSecuritiesService: ExternalSecuritiesService, private navigationService: NavigationService, private location: Location) {
    this.options = {
        title : { text : 'Provider prices history' },
        series: [{
            data: [],
        }]
    };
  }

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.getExternalSecurity();
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
    
  }

  getExternalSecurity(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.externalSecuritiesService.getExternalSecurity(id).subscribe(externalSecurity => this.externalSecurity = externalSecurity);
    this.externalSecuritiesService.getExternalSecurityHistory(id).subscribe(externalSecurityHistory => {
      let processedContent = [];
      for (let token of externalSecurityHistory) {
        processedContent.push([new Date(token.date).getTime(), token.value]);
      }
      this.options = {
        title : { text : 'Provider prices history' },
        xAxis: { type: 'datetime', title: {'text': 'Date'} },
        tooltip: {
            headerFormat: '<b>{point.x:%Y, %b %e}</b><br>',
            pointFormat: '{point.y:.4f}'
        },
        series: [{
            name: 'Prices',
            data: processedContent,
        }]
      };
    });
  }

}
