import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import base64url from 'base64url';
import { Portfolio } from './models/portfolio';
import { ExternalPortfolioHoldings } from './models/external-portfolio-holdings';
import { PortfolioAccountHolding } from './models/portfolio-account-holding';
import { Account } from './models/account';
import { ExternalTransaction } from './models/external-transaction';
import { JWTHttpClient } from './utility/jwt-http-client';

const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    })
};

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  private providerURL = environment.apiEndPoint;

  constructor(private http: JWTHttpClient) { }

  getPositions(portfolio: Portfolio): Observable<ExternalPortfolioHoldings> {
    const provider_code = portfolio.provider.provider_code.toLowerCase();
    const url = `${this.providerURL}/${provider_code}/positions/${portfolio.id}/`;
    return this.http.get<ExternalPortfolioHoldings>(url).pipe(
      tap(_ => this.log(`fetched positions from=${provider_code} id=${portfolio.id}`)),
      catchError(this.handleError<any>(`getPositions from=${provider_code} id=${portfolio.id}`))
    );
  }

  getCashOperations(portfolio: Portfolio): Observable<ExternalTransaction[]> {
    const provider_code = portfolio.provider.provider_code.toLowerCase();
    const url = `${this.providerURL}/${provider_code}/operations_cash/${portfolio.id}/`;
    return this.http.get<ExternalTransaction[]>(url).pipe(
      tap(_ => this.log(`fetched cash operations from=${provider_code} id=${portfolio.id}`)),
      catchError(this.handleError<any>(`getCashOperations from=${provider_code} id=${portfolio.id}`))
    );
  }

  getSecurityOperations(portfolio: Portfolio): Observable<ExternalTransaction[]> {
    const provider_code = portfolio.provider.provider_code.toLowerCase();
    const url = `${this.providerURL}/${provider_code}/operations_security/${portfolio.id}/`;
    return this.http.get<ExternalTransaction[]>(url).pipe(
      tap(_ => this.log(`fetched cash operations from=${provider_code} id=${portfolio.id}`)),
      catchError(this.handleError<any>(`getCashOperations from=${provider_code} id=${portfolio.id}`))
    );
  }

  acceptExternalTransaction(transaction: ExternalTransaction): Observable<any> {
    const url = `${this.providerURL}/providers/transaction/accept/${transaction.id}/`;
    return this.http.post<any>(url, {}).pipe(
      tap(_ => this.log(`accept external transaction id=${transaction.id}`)),
      catchError(this.handleError<any>(`acceptExternalTransaction id=${transaction.id}`))
    );
  }

  createAccountFrom(portfolioHoldings: ExternalPortfolioHoldings, accountHolding: PortfolioAccountHolding) : Observable<any> {
    const url = `${this.providerURL}/providers/account/${accountHolding.id}/portfolio/${portfolioHoldings.id}/`;
    return this.http.post<any>(url, {}).pipe(
      tap(_ => this.log(`created account from=${accountHolding.id} on portfolio=${portfolioHoldings.id}`)),
      catchError(this.handleError<any>(`createAccount from=${accountHolding.id} on portfolio=${portfolioHoldings.id}`))
    );
  }

  assignAccountTo(account: Account, accountHolding: PortfolioAccountHolding) : Observable<any> {
    const url = `${this.providerURL}/providers/account/${accountHolding.id}/assign/${account.id}/`;
    return this.http.post<any>(url, {}).pipe(
      tap(_ => this.log(`assign account from=${accountHolding.id} on account=${account.id}`)),
      catchError(this.handleError<any>(`assignAccount from=${accountHolding.id} on account=${account.id}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('ProviderService: ' + message);
  }

}