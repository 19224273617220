import { Component, OnInit } from '@angular/core';
import { FormControl}  from '@angular/forms';
import { ExternalSecurity } from '../models/external.security'
import { Security } from '../models/security'
import { ExternalSecuritiesService } from '../external-securities.service';
import { SecuritiesService } from '../securities.service';

import {startWith, map} from 'rxjs/operators';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-external-securities',
  templateUrl: './external-securities.component.html',
  styleUrls: ['./external-securities.component.css']
})
export class ExternalSecuritiesComponent implements OnInit {

  externalSecurities: ExternalSecurity[];

  selectedExternalSecurity: ExternalSecurity;

  filteredSecurities: Security[];

  securityController: FormControl;


  constructor(private externalSecuritiesService: ExternalSecuritiesService, private securitiesService: SecuritiesService, private navigationService: NavigationService) { }

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.getUnmapped();
        this.filteredSecurities = [];
        this.securityController = new FormControl();
        this.securityController.valueChanges.subscribe(searchedText => {
          if (searchedText.length>=3) {
            this.searchSecurities(searchedText);
          }
        });
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();

  }

  updateManagementCompany(selectedSecurityId): void {
    this.selectedExternalSecurity.associated = selectedSecurityId;
    this.externalSecuritiesService.updateExternalSecurity(this.selectedExternalSecurity).subscribe(security => this.getUnmapped());
  }

  selectExternalSecurity(ext_security): void {
    this.selectedExternalSecurity = ext_security;
  }

  searchSecurities(searchedText: string): void {
    this.securitiesService.getFilteredSecurities(searchedText).subscribe(securities => {
      console.log(securities);
      this.filteredSecurities = securities;
    });
  }

  getUnmapped(): void {
    this.externalSecuritiesService.getUnmappedExternalSecurities().subscribe(externalSecurities => this.externalSecurities = externalSecurities);
  }
}
