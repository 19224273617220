import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { NgDatepickerModule } from 'ng2-datepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PortfoliosComponent } from './portfolios/portfolios.component';
import { PortfolioService } from './portfolio.service';
import { GlobalObjectService } from './globalobject.service';
import { CommonObjectsService } from './common-objects.service';
import { ExternalSecuritiesService } from './external-securities.service';
import { SecuritiesService } from './securities.service';
import { PortfolioDetailsComponent } from './portfolio-details/portfolio-details.component';
import { ChartModule } from 'angular2-highcharts';
import { ExternalSecuritiesComponent } from './external-securities/external-securities.component';
import { ExternalSecurityDetailsComponent } from './external-security-details/external-security-details.component';
import { SecuritiesComponent } from './securities/securities.component';
import { SecurityDetailsComponent } from './security-details/security-details.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { OperationDetailsComponent } from './operation-details/operation-details.component';
import { MatInputModule, MatAutocompleteModule } from '@angular/material';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyService } from './company.service';
import { PersonsComponent } from './persons/persons.component';
import { PersonService } from './person.service';
import { LoginComponent } from './login/login.component';
import * as highcharts from 'highcharts';
import { JWTHttpClient } from './utility/jwt-http-client';


@NgModule({
  declarations: [
    AppComponent,
    PortfoliosComponent,
    PortfolioDetailsComponent,
    ExternalSecuritiesComponent,
    ExternalSecurityDetailsComponent,
    SecuritiesComponent,
    SecurityDetailsComponent,
    AccountDetailsComponent,
    OperationDetailsComponent,
    CompaniesComponent,
    PersonsComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule, FontAwesomeModule,
    NgDatepickerModule, MatInputModule, MatAutocompleteModule,
    ChartModule.forRoot(highcharts)
  ],
  providers: [JWTHttpClient, GlobalObjectService, PortfolioService, ExternalSecuritiesService, SecuritiesService, CommonObjectsService, CompanyService, PersonService],
  bootstrap: [AppComponent]
})
export class AppModule { }