import { Component, OnInit, Input } from '@angular/core';
import { Portfolio } from '../models/portfolio';
import { Account } from '../models/account';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en';

import { PortfolioService }  from '../portfolio.service';
import { CommonObjectsService } from '../common-objects.service';
import { GlobalObjectService } from '../globalobject.service';
import { GlobalObject } from '../models/global.object';
import { Person } from '../models/person';
import { Operation } from '../models/operation';
import { Company } from '../models/company';
import { PortfolioSetup } from '../models/portfolios-setup';
import { ProviderService } from '../provider.service';
import { ExternalPortfolioHoldings } from '../models/external-portfolio-holdings';

import { PortfolioAccountHolding } from '../models/portfolio-account-holding';
import { PortfolioSecurityHolding } from '../models/portfolio-security-holding';
import { Security } from '../models/security';
import { ExternalTransaction } from '../models/external-transaction';
import { SecurityHolding } from '../models/security-holding';
import { NavigationService } from '../navigation.service';

registerLocaleData(localeEn);



@Component({
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
  styleUrls: ['./portfolio-details.component.css']
})

export class PortfolioDetailsComponent implements OnInit {

  private cashTypes: string[] = ["OPE_TYPE_CASH_CONTRIBUTION", "OPE_TYPE_CASH_WITHDRAWAL", "OPE_TYPE_COMMISSION", "OPE_TYPE_CONTRIBUTION",
                                 "OPE_TYPE_FEES", "OPE_TYPE_INTEREST", "OPE_TYPE_INTERNAL_TRANSFER", "OPE_TYPE_PNL", "OPE_TYPE_TAX", "OPE_TYPE_WITHDRAWAL"];


  private portfoliosSetup: PortfolioSetup = undefined;

  @Input() portfolio: Portfolio;
  @Input() editablePortfolio: Portfolio;
  @Input() moneyAccounts : Account[];
  
  @Input() holdings: SecurityHolding[];

  currencies: GlobalObject[];
  operationTypes: GlobalObject[];
  quickCurrencies: GlobalObject[];
  workingOperationTypes: GlobalObject[];
  accountTypes: GlobalObject[];
  companies: Company[];
  persons: Person[];

  @Input() newRelationshipManager: Person;
  @Input() newBank: Company;
  @Input() newManagementCompany: Company;
  @Input() newProvider: Company;

  @Input() transactions: Operation[];

  externalPositions: ExternalPortfolioHoldings = undefined;
  externalCashTransactions: ExternalTransaction[] = undefined;
  externalSecurityTransactions: ExternalTransaction[] = undefined;

  options: object;

  generalInformationEditionPage : number = 1;
  providerLoading : boolean = false;
  computingOrLoading: boolean = false;
  accountEditCreateMode : boolean = false;
  accountEditCreateHolding : PortfolioAccountHolding = undefined;
  accountEditCreateAccount : Account = undefined;
  securityEditCreateMode : boolean = false;
  securityEditCreateHolding : PortfolioSecurityHolding = undefined;
  securityEditCreateSecurity : Security = undefined;
  operations : any[];

  constructor(private route: ActivatedRoute, private portfolioService: PortfolioService, private commonObjectsService: CommonObjectsService,
              private globalObjectService: GlobalObjectService, private providerService: ProviderService, private navigationService: NavigationService, private location: Location) {

  }

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.getPortfolio();
        this.getCommonObjects();
        this.getHoldings();
        this.getPortfoliosSetup()
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
  }

  //
  // TRANSACTIONS/OPERATIONS METHODS OR FUNCTIONS
  //


  addNewEmptyTransaction(): void {
    let operation: Operation = new Operation();
    operation['value_date_as_dt'] = new Date();
    operation['operation_date_as_dt'] = new Date();
    this.transactions.push(operation);
  }

  resetTransaction(transactionType): void {
    this.transactions = [];
    this.workingOperationTypes = [];
    for(let opType of this.operationTypes) {
      if (transactionType==='CASH' && this.cashTypes.indexOf(opType.identifier)>=0) {
        this.workingOperationTypes.push(opType);
      }
    }
    this.addNewEmptyTransaction();
  }


  //
  // BACKEND CALLS METHODS OR FUNCTIONS
  //

  getCommonObjects(): void {
    this.globalObjectService.getCurrencies().subscribe(currencies => this.currencies = currencies);
    this.globalObjectService.getFinancialOperationTypes().subscribe(operationTypes => this.operationTypes = operationTypes);
    this.globalObjectService.getQuickCurrencies().subscribe(quickCurrencies => this.quickCurrencies = quickCurrencies);
    this.globalObjectService.getAccountTypes().subscribe(accountTypes => this.accountTypes = accountTypes);
    this.commonObjectsService.getPersons().subscribe(persons => this.persons = persons);
    this.commonObjectsService.getCompanies().subscribe(companies => this.companies = companies);
  }

  getPortfolio(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.moneyAccounts = [];

    this.portfolioService.getPortfolio(id).subscribe(portfolio => {
      this.portfolio = portfolio;
      for (let account of this.portfolio.accounts) {
        if (account.type.identifier!='ACC_SECURITY') {
          this.moneyAccounts.push(account);
        }
      }
      this.preparePortfolioEdition();
    });

    this.portfolioService.getPortfolioHistory(id, 'cumulated').subscribe(portfolioHistory => {
      let processedContent = [];
      for (let token of portfolioHistory) {
        processedContent.push([new Date(token.date).getTime(), token.value]);
      }
      this.options = {
        title : { text : 'Cumulated history' },
        xAxis: { type: 'datetime', title: {'text': 'Date'} },
        tooltip: {
            headerFormat: '<b>{point.x:%Y, %b %e}</b><br>',
            pointFormat: '{point.y:.4f}'
        },
        series: [{
            name: 'Cumulated',
            data: processedContent,
        }]
      };
    });
    
  }

  //
  // COMMON PORTFOLIO METHODS OR FUNCTIONS
  //

  computeValuations() : void {
    this.computingOrLoading = true;
    this.portfolioService.computePortfolio(this.portfolio.id).subscribe(result => {
      this.getPortfolio();
      this.computingOrLoading = false;
    });
  }

  preparePortfolioEdition() : void {
    this.portfolio['inception_date_as_dt'] = new Date(this.portfolio.inception_date);
    this.portfolio['closing_date_as_dt'] = this.portfolio.closing_date?new Date(this.portfolio.closing_date):undefined;
  }

  preparePortfolioForEdition() : void {
    this.generalInformationEditionPage = 1;
    this.editablePortfolio = JSON.parse(JSON.stringify(this.portfolio));
    this.editablePortfolio['inception_date_as_dt'] = new Date(this.editablePortfolio.inception_date);
    this.editablePortfolio['closing_date_as_dt'] = this.editablePortfolio.closing_date!==undefined && this.editablePortfolio.closing_date!=null?new Date(this.editablePortfolio.closing_date):undefined;
    if (this.editablePortfolio.additional_information===undefined || this.editablePortfolio.additional_information===null) {
      this.editablePortfolio.additional_information = {}
    }
    this.portfoliosSetup.additional_information.forEach((entry: any)=> {
      if (!this.editablePortfolio.additional_information.hasOwnProperty(entry.name)) {
        this.editablePortfolio.additional_information[entry.name] = entry.default_value;
      }
      if (entry.type==='boolean') {
        this.editablePortfolio.additional_information[entry.name] = this.editablePortfolio.additional_information[entry.name].toLowerCase()==='true';
      } else if (entry.type==='number') {
        this.editablePortfolio.additional_information[entry.name] = parseInt(this.editablePortfolio.additional_information[entry.name]);
      }
    });
  }

  updateGeneralInformation() : void {
    this.portfolio = this.editablePortfolio;
    this.updatePortfolio();
  }

  updateProvider(): void {
    this.portfolio.provider = this.newProvider;
    this.updatePortfolio();
  }


  updateManagementCompany(): void {
    this.portfolio.management_company = this.newManagementCompany;
    this.updatePortfolio();
  }

  updateRelationManager(): void {
    this.portfolio.relationship_manager = this.newRelationshipManager;
    this.updatePortfolio();
  }

  updateBank(): void {
    this.portfolio.bank = this.newBank;
    this.updatePortfolio();
  }

  updatePortfolio() : void {
    let offset: number = this.portfolio['inception_date_as_dt'].getTimezoneOffset() * 60000;
    this.portfolio.inception_date = new Date(this.portfolio['inception_date_as_dt'].getTime() - offset).toISOString().substring(0,10);
    if (this.portfolio['closing_date_as_dt']!==undefined && this.portfolio['closing_date_as_dt']!==null && this.portfolio['closing_date_as_dt']!=='') {
      offset = this.portfolio['closing_date_as_dt'].getTimezoneOffset() * 60000;
      this.portfolio.closing_date = new Date(this.portfolio['closing_date_as_dt'].getTime() - offset).toISOString().substring(0,10);
    }
    this.portfolioService.updatePortfolio(this.portfolio).subscribe(portfolio => this.getPortfolio());
  }


  //
  // HOLDINGS METHODS OR FUNCTIONS
  //

  getHoldings(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.portfolioService.getPortfolioHoldings(id).subscribe(holdings => {
      this.holdings = holdings;
      this.operations = [];
      this.holdings.forEach(value => {
        this.operations.push({"display": false, "loading": false, "data": undefined});
      })
      console.log(this.holdings);
    });
  }

  toggleOperationsDisplay(holding: SecurityHolding, index: number) : void {
    if (this.operations[index]!==undefined && this.operations[index].display) {
      this.operations[index].display = false;
      this.operations[index].data = undefined;
      this.operations[index].loading = false;
    } else {
      this.operations[index].display = false;
      this.operations[index].loading = true;
      this.portfolioService.getPortfolioSecurityOperations(this.portfolio, holding.holding_account_id, holding.security.id)
                           .subscribe(operations => {
                             this.operations[index].data = operations;
                             this.operations[index].loading = false;
                             this.operations[index].display = true;
                            });
    }
  }
  //
  // PROVIDERS METHODS OR FUNCTIONS
  //

  getProviderPositions() : void {
    this.providerLoading = true;
    this.externalPositions = undefined;
    this.providerService.getPositions(this.portfolio).subscribe((positions: ExternalPortfolioHoldings)=>{
      this.providerLoading = false;
      this.accountEditCreateMode = false;
      this.securityEditCreateMode = false;
      this.externalPositions = positions;
    });
  }

  createOrSelectAccount(accountHolding: PortfolioAccountHolding) : void {
    this.accountEditCreateHolding = accountHolding;
    this.accountEditCreateMode = true;
    console.log(this.accountEditCreateHolding);
  }

  createAccountFrom(accountHolding: PortfolioAccountHolding) : void {
    this.providerService.createAccountFrom(this.externalPositions, accountHolding).subscribe(data => {
      this.getProviderPositions();
      this.computeValuations();
    })
  }

  assignAccountTo(accountHolding: PortfolioAccountHolding) : void {
    this.providerService.assignAccountTo(this.accountEditCreateAccount, accountHolding).subscribe(data => {
      this.getProviderPositions();
      this.computeValuations();
    })
  }

  acceptProviderPositions() : void {

  }

  acceptExternalTransaction(transaction: ExternalTransaction) : void {
    this.providerService.acceptExternalTransaction(transaction).subscribe(data => {
      this.computeValuations();
      this.getProviderOperations();});
  }

  getProviderOperations() : void {
    this.providerLoading = true;
    this.externalCashTransactions = undefined;
    this.externalSecurityTransactions = undefined;
    this.providerService.getCashOperations(this.portfolio).subscribe((cashTransactions: ExternalTransaction[])=>{
      this.providerLoading = true;
      this.externalCashTransactions = cashTransactions;
      this.providerService.getSecurityOperations(this.portfolio).subscribe((securityTransactions: ExternalTransaction[])=>{
        this.providerLoading = false;
        this.externalSecurityTransactions = securityTransactions;
      });
    });
  }

  //
  // GENERAL INFORMATION EDITION METHODS OR FUNCTIONS
  //
  
  previousGeneralInformationEditionPage() : void {
    this.generalInformationEditionPage = this.generalInformationEditionPage - 1;
  }

  nextGeneralInformationEditionPage() : void {
    this.generalInformationEditionPage = this.generalInformationEditionPage + 1;
  }

  //
  // SETUP METHODS OR FUNCTIONS
  //

  getPortfoliosSetup() : void {
    this.portfolioService.getSetup().subscribe(setup => this.portfoliosSetup =  setup);
  }

  //
  // GENERIC METHODS OR FUNCTION
  //

  globalObjectComparator(o1 : GlobalObject, o2 : GlobalObject) : boolean {
    if (o1==undefined || o2==undefined) {
      return false;
    }
    return o1.identifier===o2.identifier;
  }

}
