import { Component, OnInit, Input } from '@angular/core';
import { Security } from '../models/security'

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { SecuritiesService }  from '../securities.service';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-security-details',
  templateUrl: './security-details.component.html',
  styleUrls: ['./security-details.component.css']
})
export class SecurityDetailsComponent implements OnInit {

  @Input() security: Security;
  options: Object;

  aliases_key : string[] = [];

  last_price: number = 0.0;
  last_price_date: string = "N/A";
  last_aum: number = 0.0;
  last_aum_date: string = "N/A";

  constructor(private route: ActivatedRoute, private securityService: SecuritiesService, private navigationService: NavigationService, private location: Location) { }

  ngOnInit() {
    this.navigationService.userLoginStatus.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.getSecurity();
      } else {
        this.navigationService.routeToLogin();
      }
    });
    // Checking if logged in
    this.navigationService.checkLoggedIn();
    
  }

  getSecurity(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.securityService.getSecurity(id).subscribe(security => {
      this.security = security;
      this.aliases_key = Object.keys(security.additional_description['aliases']);
    });
    this.securityService.getSecurityHistory(id).subscribe(securityHistory => {
      let processedContent = [];
      for (let token of securityHistory) {
        processedContent.push([new Date(token.date).getTime(), token.value]);
        this.last_price = token.value;
        this.last_price_date = token.date;
      }
      this.options = {
        title : { text : 'Provider prices history' },
        xAxis: { type: 'datetime', title: {'text': 'Date'} },
        tooltip: {
            headerFormat: '<b>{point.x:%Y, %b %e}</b><br>',
            pointFormat: '{point.y:.4f}'
        },
        series: [{
            name: 'Prices',
            data: processedContent,
        }]
      };
    });
  }

}
