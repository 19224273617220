
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const refreshURL: string = environment.apiEndPoint + '/api/refresh/';

@Injectable()
export class JWTHttpClient {

  @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {}

  getHeaders() {
    const headers = new HttpHeaders(
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('finance_jwt_token'),
        'Cache-Control': 'no-cache',
      }
    );
    return headers;
  }

  refreshToken() {
    const refreshedToken = localStorage.getItem('finance_jwt_token_refresh');
    this.http.post(refreshURL, {
        'refresh': refreshedToken
    }).subscribe(
        response => {
            console.log(response);
            localStorage.setItem('finance_jwt_token', response['access']);
            this.refreshEvent.emit(true);
        }
    );
    return;
}

  get<T>(url): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.getHeaders()
    });
  }

  delete<T>(url): Observable<T> {
    const headers = new Headers();
    return this.http.delete<T>(url, {
      headers: this.getHeaders()
    });
  }

  post<T>(url, data): Observable<T> {
    const headers = new Headers();
    return this.http.post<T>(url, data, {
      headers: this.getHeaders()
    });
  }

  put<T>(url, data): Observable<T> {
    const headers = new Headers();
    return this.http.put<T>(url, data, {
      headers: this.getHeaders()
    });
  }

  patch<T>(url, data): Observable<T> {
    const headers = new Headers();
    return this.http.patch<T>(url, data, {
      headers: this.getHeaders()
    });
  }
}