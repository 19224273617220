import { GlobalObject } from './global.object';
import { Company } from './company';

export class Account {
  id : number;
  identifier : string;
  name : string;
  currency : GlobalObject;
  active : boolean;
  bank : Company;
  type: GlobalObject;
  current_amount_local : number;
  current_amount_portfolio : number;
  include_valuation : boolean;
  inception_date : string;
  closing_date : string;
  last_update : string;
  last_computation : string;
  additional_information : object;
  additional_description : object;
}