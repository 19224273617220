import { GlobalObject } from './global.object';
import { Address } from './address';
import { Email } from './email';
import { Phone } from './phone';

export class Person {
    id: number;
    default_name : string;
    first_name : string;
    last_name : string;
    birth_date : string;
    addresses : Address[];
    emails : Email[];
    phones : Phone[];
    active : boolean;
    picture : string;
}