import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalObject } from './models/global.object';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { JWTHttpClient } from './utility/jwt-http-client';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GlobalObjectService {

  private baseURL = environment.apiEndPoint  + '/';

  constructor(private http: JWTHttpClient) {
  }

  getCurrencies(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'currencies/')
      .pipe(  tap(currencies => this.log('fetched currencies')),
              catchError(this.handleError('getCurrencies', []))
      );
  }

  getQuickCurrencies(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_currencies/')
      .pipe(  tap(currencies => this.log('fetched currencies')),
              catchError(this.handleError('getQuickCurrencies', []))
      );
  }

  getCountries(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'countries/')
      .pipe(  tap(countries => this.log('fetched countries')),
              catchError(this.handleError('getCountries', []))
      );
  }

  getQuickCountries(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_countries/')
      .pipe(  tap(countries => this.log('fetched countries')),
              catchError(this.handleError('getQuickCountries', []))
      );
  }

  getVisibilityLevels(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'visibility_levels/')
      .pipe(  tap(visibility_levels => this.log('fetched visibility_levels')),
              catchError(this.handleError('getVisibilityLevels', []))
      );
  }

  getQuickVisibilityLevels(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_visibility_levels/')
      .pipe(  tap(visibility_levels => this.log('fetched visibility_levels')),
              catchError(this.handleError('getQuickVisibilityLevels', []))
      );
  }

  getAddressTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'address_types/')
      .pipe(  tap(address_types => this.log('fetched address_types')),
              catchError(this.handleError('getAddressTypes', []))
      );
  }

  getQuickAddressTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_address_types/')
      .pipe(  tap(address_types => this.log('fetched address_types')),
              catchError(this.handleError('getQuickAddressTypes', []))
      );
  }

  getPhonesTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'phone_types/')
      .pipe(  tap(phone_types => this.log('fetched phone_types')),
              catchError(this.handleError('getPhoneTypes', []))
      );
  }

  getQuickPhoneTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_phone_types/')
      .pipe(  tap(phone_types => this.log('fetched phone_types')),
              catchError(this.handleError('getQuickPhoneTypes', []))
      );
  }

  getMailTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'mail_types/')
      .pipe(  tap(mail_types => this.log('fetched mail_types')),
              catchError(this.handleError('getMailTypes', []))
      );
  }

  getQuickMailTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_mail_types/')
      .pipe(  tap(mail_types => this.log('fetched mail_types')),
              catchError(this.handleError('getQuickMailTypes', []))
      );
  }

  getAccountTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'account_types/')
      .pipe(  tap(mail_types => this.log('fetched account_types')),
              catchError(this.handleError('getAccountTypes', []))
      );
  }

  getQuickAccountTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_account_types/')
      .pipe(  tap(mail_types => this.log('fetched account_types')),
              catchError(this.handleError('getQuickAccountTypes', []))
      );
  }

  getOperationStatuses(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'operation_statuses/')
      .pipe(  tap(operation_statuses => this.log('fetched operation_statuses')),
              catchError(this.handleError('getOperationStatuses', []))
      );
  }

  getQuickOperationStatuses(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_operation_statuses/')
      .pipe(  tap(operation_statuses => this.log('fetched operation_statuses')),
              catchError(this.handleError('getQuickOperationStatuses', []))
      );
  }

  getFinancialOperationTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'financial_operation_types/')
      .pipe(  tap(financial_operation_types => this.log('fetched financial_operation_types')),
              catchError(this.handleError('getFinancialOperationTypes', []))
      );
  }

  getQuickFinancialOperationTypes(): Observable<GlobalObject[]> {
    return this.http.get<GlobalObject[]>(this.baseURL + 'quick_financial_operation_types/')
      .pipe(  tap(financial_operation_types => this.log('fetched financial_operation_types')),
              catchError(this.handleError('getQuickFinancialOperationTypes', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('GlobalObjectService: ' + message);
  }

}
