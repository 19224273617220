import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Security } from './models/security'
import { TrackToken } from './models/track.token';

import base64url from 'base64url';
import { JWTHttpClient } from './utility/jwt-http-client';

const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    })
};

@Injectable()
export class SecuritiesService {

  private securitiesURL = environment.apiEndPoint  + '/securities';

  constructor(private http: JWTHttpClient) { }

  getSecurity(id: number): Observable<Security> {
    const url = `${this.securitiesURL}/${id}/`;
    return this.http.get<Security>(url).pipe(
      tap(_ => this.log(`fetched security id=${id}`)),
      catchError(this.handleError<Security>(`getSecurity id=${id}`))
    );
  }

  getSecurities() : Observable<Security[]> {
    return this.http.get<Security[]>(`${this.securitiesURL}/`)
      .pipe(  tap(securities => this.log('fetched securities')),
              catchError(this.handleError('getSecurities', []))
      );
  }

  getFilteredSecurities(_filter: string) : Observable<Security[]> {
    const url = `${this.securitiesURL}_search/` + base64url(_filter) + '/';
    return this.http.get<Security[]>(url)
      .pipe(  tap(securities => this.log('fetched filtered securities')),
              catchError(this.handleError('getFilteredSecurities', []))
      );
  }

  getSecurityHistory(id: number) : Observable<TrackToken[]> {
    const url = `${this.securitiesURL}_history/${id}/`;
    return this.http.get<TrackToken[]>(url)
      .pipe(  tap(securityHistory => this.log('fetched security history')),
              catchError(this.handleError('getSecurityHistory', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('SecuritiesService: ' + message);
  }

}
