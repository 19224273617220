import { GlobalObject } from './global.object';
import { Company } from './company'
import { Security } from './security'

export class ExternalSecurity {
    id: number;
    name : string;
    type : GlobalObject;
    currency : GlobalObject;
    provider : Company;
    provider_identifier : String;
    associated : Security;
    potential_matches : Security[];
}